<template>
  <div>
    <el-row class="dash-board">
      <el-col :span="7">
        <p class="in1">培训班名称:</p>
        <el-input style="width: 200px" class="e-inps" v-model="className"></el-input>
      </el-col>
      <el-col :span="3" style="display: flex; align-items: center;">
        <el-button type="primary" @click="schedulelist" class="inquire">查询</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
      </el-col>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;">
        <el-table-column
          label="培训班名称"
          align="center"
          width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.className }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="课程类别"
          align="center"
          width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.category }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="培训时间"
          align="center"
          width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.openTime }}~{{ scope.row.endTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="报到地点"
          align="center"
          width="400">
          <template slot-scope="scope">
            <span>{{ scope.row.trainLocation }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center">
          <template slot-scope="scope">
            <!-- <a v-if="scope.row.flag !== 0" :href="(scope.row.scheduleUrl)">下载查看</a> -->
            <el-button type="primary" @click="seeBt(scope.row)" >查看课程</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { scheduleByUsername } from '@/api/training.js'
  export default {
    name: 'Schedule',
    data () {
      return {
        tableData: [],
        className: '',
        total: 0,
        pageNum: 1,
        pageSize: 10
      }
    },
    mounted () {
      this.schedulelist()
    },
    methods: {
      seeBt(row) {
        this.$router.push({ path: '/CurriculumList', query: { id: row.classId, className: row.className } })
      },
      schedulelist () {
        const query = {
          className: this.className,
          userName: this.$store.getters.username,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        scheduleByUsername(query).then(resp => {
          if (resp.data.code === '200') {
            this.tableData = resp.data.data.rows
            this.total = resp.data.data.total // 查询总条数
          }
        })
      },
      reset() {
        this.className = ''
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.schedulelist()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.schedulelist()
      }
    }
  }
</script>

<style scoped>
.reset{margin-left: 50px;}
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .e-inps{position: absolute; left: 100px; top: 14px}
  .dash-board {display: flex; background-color: white; height: 60px; padding: 10px 0;}
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  a{margin:auto; width: 70px; line-height: 28px; display: block; border-radius:4px;
    color: #FFF; background-color: #409EFF; border-color: #409EFF;text-align:center;
    font-size:12px; text-decoration:none; cursor:pointer;}
  a:hover{color: #FFF; background-color: #70b3f9; border-color: #70b3f9; text-decoration:none; }
</style>
